<template>
  <div class="box">
    <div class="container">
      <p>{{this.$t('privacyPolicy.title')}}</p>
      <div class="text_box">
        <div class="text">
          <p>{{ this.$t('privacyPolicy.content') }}
          </p>
          <span>{{ this.$t('privacyPolicy.title_a') }}</span>
          <hr />
          <p>{{ this.$t('privacyPolicy.content_a') }}</p>
          <span>{{this.$t('privacyPolicy.title_b')}}</span>
          <hr />
          <p>{{ this.$t('privacyPolicy.content_b') }}</p>
          <span>{{this.$t('privacyPolicy.title_c')}}</span>
          <hr />
          <p>{{ this.$t('privacyPolicy.content_c') }}</p>
          <span>{{this.$t('privacyPolicy.title_d')}}</span>
          <hr />
          <p>{{ this.$t('privacyPolicy.content_d') }}</p>
          <span>{{ this.$t('privacyPolicy.title_e') }}</span>
          <hr />
          <p>{{ this.$t('privacyPolicy.content_e1') }}</p>
          <br />
          <p>{{ this.$t('privacyPolicy.content_e2') }}</p>
          <span>{{ this.$t('privacyPolicy.title_f') }}</span>
          <hr />
          <p>{{ this.$t('privacyPolicy.content_f') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="less">
.box {
  .container {
    > p {
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 30px;
      font-family: 'Meiryooo';
      line-height: 40px;
      color: #000000;
      opacity: 1;
      font-weight: 600;
      text-align: center;
    }
    .text_box {
    //   padding: 0 !important;
      margin: 0 12%;
      background-color: #ffffff;
      text-align: left;
      .text {
        margin-top: 48px;
        // margin: 20px 18% 48px 18%;
        span {
          display: block;
          text-align: center;
          font-size: 22px;
          font-family: 'Meiryooo';
          line-height: 22px;
          color: #000000;
          opacity: 1;
          margin-top: 20px;
        }
        p {
          text-indent: 2em;
          font-size: 16px;
          font-family: 'Meiryooo';
          line-height: 26px;
          color: #000000;
          opacity: 1;
          margin-bottom: 30px;
          margin-top: 10px;
        }
        hr {
          border: lightgray 1px solid;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .box .container .text_box .text {
    margin: 48px 0;
  }
}
</style>